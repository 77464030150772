<template>
  <div>
    <Card>
      <template #table-column="{ item2 }">
        <v-btn
          :ripple="false"
          icon
          link
          :to="`/requests/invoice/${item2.id}`"
          width="34px"
          height="34px"
          class="me-4 text-xs"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <!-- <template #list-item-table="{ item2 }">
       
      </template> -->
    </Card>
    <Modal :data="modal_data"> </Modal>
  </div>
</template>
  <script>
import { mapActions, mapGetters } from "vuex";
import Card from "../Components/Card.vue";
import Modal from "../Components/Modal.vue";
export default {
  name: "Dashboard",
  components: {
    Card,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "600px",
        title: this.$i18n.t("role.Edit"),
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          item: true,
          url: "requests/edit/",
          permission: "",
        },
        // {
        //   type: "icon",
        //   text: "delete_user",
        //   color: "bg-gradient-success",
        //   icon: "mdi-delete",
        //   permission: "user-delete",
        // },
      ],
      header: [
        {
          text: this.$i18n.t("request.version_number"),
          align: "center",
          value: "version_number",
        },
        {
          text: this.$i18n.t("daily.total_count_hour"),
          value: "count_hour",
          align: "center",
        },
        {
          text: this.$i18n.t("daily.total_amount"),
          value: "price",
          align: "center",
        },
        {
          text: this.$i18n.t("request.total_price"),
          value: "total_price",
          align: "center",
        },
        {
          text: this.$i18n.t("request.request_date"),
          value: "request_date",
          align: "center",
        },
        {
          text: this.$i18n.t("general.details"),
          value: "column",
          align: "center",
        },
        { text: this.$i18n.t("Action"), value: "btns", align: "center" },
      ],
      card: {
        title: this.$i18n.t("Requests"),
        add_url: "/requests/create",
        permission: "request-create",
      },
      load_excel: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["str_per"]),
    classLangBtn() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    classBtn(val) {
      return this.$store.commit("table/SET_CHECK_PER", val);
    },
    set_data() {
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_COLLECTION", "user");
      this.$store.commit("table/SET_LOADING", true);
      this.$store.commit("table/SET_HEADERS", this.header);
      this.$store.commit("table/SET_BTNS", this.btns);
      this.$store.commit("table/SET_PAGINATION", true);
      this.$store.commit("SET_URL", "requests");
    },
  },
  created() {
    console.log(this.$route.params.message);
    if (this.$route.params.message) {
      this.$store.commit("form/SET_NOTIFY", this.$route.params.message);
    }
  },
  mounted() {
    // this.getUsers();
    this.set_data();
    this.$store.dispatch("user/getData", { reset: true }).then(
      (response) => {
        // this.$store.commit('table/SET_ITEMS')
      },
      (error) => {}
    );

    document.title = this.$i18n.t("Requests");
  },
};
</script>
  